<template>
    <section>
        <div id="player"></div>
    </section>
</template>

<script>

export default {
props: ["uri", "type", "data"],

data: () => ({
    height: 500,
    player: null,
    loaded: false
}),

mounted() {
    this.loadPlayerAPI();
},
methods: {
    loadPlayerAPI() {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/player_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubePlayerAPIReady = () => {
            this.player = new window.YT.Player(this.$refs.player, {
            videoId: this.uri.replace('https://youtube.com/embed/', ''),
            playerVars: {
                controls: 1,
                autoplay: 0,
            },
            });
        };
    },
    onPlayerReady(event) {
    // Player is ready
    },
}
};
</script>
  